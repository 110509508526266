/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getArticles } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import Pagination from '../../component/pagination';
import { formatMonth } from '../../data/format-month';
import { base } from '../../store/api';
import Loading from '../../component/Loading';
import { cutLetter } from '../../utils';

function BlogClassic(props) {
  const language = localStorage.getItem('i18nextLng')


  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  return (
    <Link to={{ pathname: `/article-details/${props.id}` }}>
      <article className={`blog-post article-post blog-classic blog-elements ${props.postType || ''}`}>
        <div className="post-image">
          <img
            className="w-100 img-fluid"
            src={props.imgSrc}
            alt={props.title}
          />
        </div>
        <div className="entry-content">
          <div className="entry-meta">
            <ul>
              <li key="meta-category">
                <img src='/images/calendar.svg' className='' alt='calendar' />
              </li>
              <li key="meta-date" className="entry-meta-date">
                <div>{formatDate(props.date)}</div>
              </li>
            </ul>
          </div>
          <h4 className="entry-title">
            <Link style={{lineClamp: 1}} to={{ pathname: `/article-details/${props.id}` }}>
              {cutLetter({letter: props.title, maxSize: 40})}
            </Link>
          </h4>
          <div className="entry-description">
            <div>{props.description}</div>
          </div>
        </div>
      </article>
    </Link>
  );
}

function Article() {
  const dispatch = useDispatch()
  const [articles, setArticles] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    setLoading(true)
    getArticles(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setLoading(false)
      setArticles(res?.results)
      setCount(res?.count)
    })
  }, [language, currentPage])



  return (
    <>
      <div className="site-content">
        <PageHeader
          title={t("articles")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("articles_desc")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col sm={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-2 grid-sm-1">
                    {articles.length ? articles.map((val, ind) => {
                      return (
                        <BlogClassic
                          key={ind}
                          id={val.id}
                          imgSrc={val.images.length ? `${base}${val.images[0].thumbnail}` : ''}
                          // postType={val.postType}
                          // category={val.category}
                          date={val.timestamp}
                          title={val.title}
                          description={val.description}
                        />
                      );
                    }) : ''}
                  </div>
                </Col>
              }
            </Row>

            <Pagination currentPage={currentPage} count={count} handlePageChange={handlePageChange} />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Article;
